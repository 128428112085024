import React, { useEffect, useState } from 'react'
import { graphql, navigate, PageProps } from 'gatsby'

import Footer from '../components/Footer'
import Layout from '../Layout'
import SEO from '../SEO'

interface Props extends PageProps {
    data: {
        sanityArchiveSearch: {
            questions: {
                question: string
                answers: {
                    label: string
                    value: string
                }[]
            }[]
        }
    }
}

const Archive: React.FC<Props> = ({ data }) => {
    const { questions } = data.sanityArchiveSearch

    const [question, setQuestion] = useState<number>(-1)
    const [answers, setAnswers] = useState<string>('')

    useEffect(() => {
        if (answers[0] === 'N') {
            navigate('/archivsuche-fragen')
        } else if (question + 1 >= questions.length) {
            navigate('/archivsuche-ergebnisse?r=' + answers.replace('Y,', ''))
        } else if (question >= 0) {
            setQuestion(question + 1)
        } else {
            setQuestion(0)
        }
    }, [answers])

    return (
        <div>
            <Layout currentPage="archivsuche">
                <SEO title="Was Wa(h)r - Archivsuche" />
            </Layout>

            <div className="archive">
                {questions.length > 0 && question >= 0 && question < questions.length && (
                    <div className="archive-form">
                        <label htmlFor="hasinfo">{questions[question].question}</label>
                        <div className="archive-form-group">
                            {questions[question].answers.map((q, i) => (
                                <button
                                    key={i}
                                    onClick={() =>
                                        setAnswers(
                                            answers +
                                                questions[question].answers[i].value +
                                                (question + 1 < questions.length ? ',' : '')
                                        )
                                    }
                                >
                                    {q.label}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <Footer />
        </div>
    )
}

export const query = graphql`
    query {
        sanityArchiveSearch {
            questions {
                question
                answers {
                    label
                    value
                }
            }
        }
    }
`

export default Archive
